<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="$t('overview.my_x', [this.formatModuleName(this.$route.name, this.$i18n)])"
        :sort="'number'"
        :sortOrder="-1"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :modalSize="'modal-xl'"
        :modalWidth="'1400px'"
        :modalHeight="'90%'"
        downloadFileType="pdf"
        :autoSaveEnabled="true"
        :selectFields=selectFields
        :filterOptions="filterOptions"
        :extraOptions=extraOptions
        itemNameField="number"
        :checkIfCanShowDelete="checkIfCanShowDelete"
    >
        <template #columns>
            <Column field="number" :header="$t('navigation.quotations')" style='width: 8rem' :sortOrder="-1"></Column>
            <Column field="customer.relation.name" :header="$tc('navigation.customers')"></Column>
            <Column field="valid_from" :header="$t('overview.date')" style='width: 8rem' :sortOrder="-1">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.valid_from, 'shortDate')}}
                </template>
            </Column>
            <Column :header="$t('sales.loading_ports')" :sortable="false">
                <template #body="slotProps">
                    {{getPoLs(slotProps.data)}}
                </template>
            </Column>
            <Column :header="$t('sales.discharge_ports')" :sortable="false">
                <template #body="slotProps">
                    {{getPoD(slotProps.data)}}
                </template>
            </Column>
            <Column field="sales_manager.name" sortField="user_id" :header="$t('overview.salesmanager')"></Column>
            <Column field="creator.name" sortField="creator_id" :header="$t('overview.creator')"></Column>
            <Column field="status" :header="$t('overview.status')" style='width:6rem'>
                <template #body="slotProps">
                    <span class="badge" :data-status="checkIfExpired(slotProps) ? 'expired' : slotProps.data.status">
                        {{ $t('quote_status.' + (checkIfExpired(slotProps) ? 'expired' : slotProps.data.status)) }}
                    </span>
                </template>
            </Column>
        </template>

        <template #form>
            <QuotationForm ref="ItemForm" :itemData="item" :formWrapper="this" />
        </template>

        <template #modal-footer>
            <div id="saveeditjobbutton" class="m-0" style="display: none">
                <FormItem type="download" :label="$t('sales.save_accept')" style="margin-right: 25px" :options="{'send': true}" startTag="fal fa-edit" :service="saveEditJob" />
            </div>
            <button id="nextbutton" type="button" class="btn btn-success" @click.prevent="next">&gt;&ensp;{{$t('form.continue_to_step')}}<span id="nextStep">2</span></button>
            <div id="hide-surcharge-slider" class="m-0" style="display: none">
                <FormItem type="slider" :label="$t('sales.show_surcharges')" sizeLabel="8" sizeItem="3" :options="[1, 0]" v-model="hide_surcharges" @onchange="setHideSurcharge" :disabled="hide_surcharges_disabled" />
            </div>
            <div id="downloadshowpdfbutton" class="m-0" style="display: none">
                <FormItem type="download" :label="$t('accounting.show_pdf')" style="margin-right: 25px" startTag="fal fa-file-pdf" :service="downloadPDF" />
            </div>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import QuotationForm from '@/components/quotation/QuotationForm.vue';
import quotationService from '@/services/QuotationService';
import customerService from '@/services/CustomerService';
import userservice from '@/services/UserService'

export default {
    components: {
        Overview,
        Column,
        QuotationForm
    },
    data() {
        return {
            item: {},
            now: '',
            hide_surcharges: 0,
            hide_surcharges_disabled: false,
            itemService: quotationService,
            itemFormRef: null,
            extraOptions: [],
            filterOptions: [
                {
                    field: 'customer_id',
                    service: customerService,
                    name: this.$t('navigation.customers')
                },
                {
                    field: 'status',
                    checkboxes: [
                        {id: "'accepted'", name: this.$t('quote_status.accepted')},
                        {id: "'declined'", name: this.$t('quote_status.declined')},
                        {id: "'draft'", name: this.$t('quote_status.draft')},
                        {id: "'pending'", name: this.$t('quote_status.pending')},
                        {id: "'expired'", name: this.$t('quote_status.expired')}],
                    name: this.$t('overview.status')
                }, 
                {
                    field: 'user_id',
                    service: userservice,
                    multiple: true,
                    name: this.$tc('overview.salesmanager', 2)
                }
            ],
            selectFields: [
                "id",
                "number",
                "valid_from",
                "is_forward",
                "customer_id",
                "status",
                "user_id",
                "valid_till",
                "creator_id",
                "created_at",
                {
                    "customer": [
                        "id",
                        "relation_id",
                        {
                            "relation": [
                                "id",
                                "name"
                            ]
                        }
                    ]
                },
                {
                    "salesManager": [
                        "id",
                        "name"
                    ]
                },
                {
                    "creator": [
                        "id",
                        "name"
                    ]
                },
                {
                    "portsOfLoading": [
                        "quote_port_of_loading.id",
                        "port_id",
                        "quote_id",
                        {
                            "port": [
                                "id",
                                "name"
                            ]
                        }
                    ]
                },
                {
                    "portsOfDischarge": [
                        "quote_port_of_discharge.id",
                        "name"
                    ]
                }
            ]
        }
    },
    methods: {
        next() {
            let tabs = document.querySelectorAll("#formModal ul.nav-tabs .nav-link");
            let next = false;
            for(let tab of tabs) {
                if(next) {
                    tab.click();
                    document.getElementById("nextStep").innerHTML = Number(tab.innerHTML[0]) + 1;
                    break;
                }
                if(tab.className.includes("active")){
                    next = true;
                }
                
            }
        },
        setSlider(hide, disable){
            this.hide_surcharges = hide;
            this.hide_surcharges_disabled = disable;
        },
        setHideSurcharge(e){
            this.$refs.ItemForm.quotation.hide_surcharges = this.hide_surcharges;
            this.$refs.ItemForm.calcCostSummary(this.hide_surcharges);
        },
        getPoLs(item){
            let result = '';
            if (!item.ports_of_loading[0]) return result
            result += item.ports_of_loading[0].port.name;
            if(item.ports_of_loading.length > 1) result += (' +' + (item.ports_of_loading.length - 1));
            return result;
        },
        getPoD(item){
            if (!item.ports_of_discharge[0]) return '';
            return item.ports_of_discharge[0].name;
        },
        checkIfExpired(sp){
            const status = sp.data.status;
            return (status == 'draft' || status == 'pending') && sp.data.valid_till <= this.now;
        },
        checkIfCanShowDelete(item){
            return item.status != 'accepted';
        },
        async downloadPDF() {
            return quotationService.downloadPDF(this.itemFormRef.quotation.id).then(response => {
                return this.triggerDownload(response.data, `${this.itemFormRef.quotation.number}.pdf`);
            }).catch(error => this.toastError(error) );
        },
        async saveEditJob() {
            this.itemFormRef.quotation.status = "accepted";
            let validate = this.itemFormRef.validate();
            if(!validate) return false;
            let item = this.itemFormRef.getData();
            return quotationService.update(item).then((response) => {
                window.location.href = window.location.origin + `/jobs#${response.data.id}`;
            }).catch(error => {
                this.toastError(error)
            });
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;

        const t = new Date();
        const date = ('0' + t.getDate()).slice(-2);
        const month = ('0' + (t.getMonth() + 1)).slice(-2);
        const year = t.getFullYear();
        this.now = `${year}-${month}-${date}`;

        this.extraOptions = [{
            icon: 'clipboard',
            name: this.$t('form.copy', [this.$t('form.link').toLowerCase()]),
            function: (slotProps) => {
                let text = `https://feedback.nmtmainport.com/` + slotProps.data.id;
                navigator.clipboard.writeText(text);
                this.$toast.info(this.$t('form.copied'));
            }
        }];
        
    }
}
</script>

<style scoped>
.fa-circle{
    font-size: 12px
}

.badge-small {
    width: 35px;
}

.badge-small.badge-fwd {
    color: #0d6efd !important;
    background-color: #fff !important;
    border: 1px solid #0d6efd;
}

</style>